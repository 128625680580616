<template>
	<div class="row mt-1">
		<div class="col-md-12 card">
			<div class="row">
				<div class="col-md-3 mt-1 pt-1">
					<router-link :to="{ path :'/layouts/detail/'+this.layoutID}"
						class="btn btn-sm btn-primary">
						<i class="fa fa-backward"></i> Back
					</router-link>
				</div>
			</div>
			<form @submit.prevent="createOffers()" class="pt-2">
				<div class="form-group row">
					<label for="" class="col-md-3"> Search Item <br /> </label>
					<div class="col-sm-7">
						<multiselect
							v-model="filter"
							label="name"
							track-by="name"
							placeholder="Type to search"
							open-direction="bottom"
							:options="ebooks"
							:searchable="true"
							:loading="isLoadingSearch"
							:close-on-select="true"
							:options-limit="50"
							:limit="50"
							:clear-on-select="true"
							:custom-label="customLabel"
							@search-change="asyncFind"
							@select="actionFindOne"
							trim
						>
							<template slot="tag" slot-scope="{ option, remove }">
								<span class="custom__tag"
									><span>{{ option.name }}</span
									><span class="custom__remove" @click="remove(option)"
										>❌</span
									></span
								></template
							>

							<span slot="noResult"
								>Oops! No elements found. Consider changing the search
								query.</span
							>
						</multiselect>
						
					</div>
				</div>
				<div class="form-group row">
					<div class="col-md-3">
						<label for="">Item type </label>
					</div>
					<div class="col-md-4" v-if="filter">
						<strong>
							{{ filter?.item_type  ?? '' }}
						</strong>
					</div>
				</div>
				<div class="form-group row">
					<div class="col-md-3">
						<label for="">Brand</label>
					</div>
					<div class="col-md-4" v-if="filter">
						{{ filter?.brands?.name ?? '' }}
					</div>
				</div>
					<div class="form-group row">
					<div class="col-md-3">
						<label for="">Vendor</label>
					</div>
					<div class="col-md-4" v-if="filter">
						<strong>
							{{ filter?.brands?.vendor?.name ?? '' }}
						</strong>
					</div>
				</div>
				<div class="form-group row">
					<div class="col-md-3">
						<label for="">Image </label>
					</div>
					<div class="col-md-3" v-if="filter && filter.thumb_image_normal">
						<b-card-img 
							:src="basePath + removeBucket(filter.thumb_image_normal)"  
							alt="Image" class="img-sm rounded-0 cover">
						</b-card-img>
					</div>
				</div>
				<div class="form-group row">
					<div class="col-md-3">
						<label for="">Internal Content</label>
					</div>
					<div class="col-md-4" v-if="filter">
						{{ filter?.ci?.is_internal_content || filter?.is_internal_content || false }}
					</div>
				</div>
				<div class="form-group row">
					<div class="col-md-3">
						<label for=""> Is Active</label>
					</div>
					<div class="col-md-4" v-if="filter">
						<span v-if ="filter.is_active" class="badge badge-primary">
							Active
						</span>
						<span v-else class="badge badge-danger">
							Inactive
						</span>
					</div>
				</div>
				<div class="form-group row">
					<div class="col-md-3">
						<label for="">Item Status</label>
					</div>
					<div class="col-md-4" v-if="filter">
						<span v-if="filter.item_status=='ready for consume'" class="badge badge-primary">
							{{ filter?.item_status ?? "" }}
						</span>
						<span v-else class="badge badge-danger">
							{{ filter?.item_status ?? "" }}
						</span>
					</div>
				</div>
				<div class="form-group row">
					<div class="col-md-3">
						<label for="">Single Price</label>
					</div>
					<div class="col-md-4" v-if="ebook && ebook.single">
						{{ formatPrice(ebook.single[0]?.price_idr ?? 0) }}
					</div>
				</div>
				<div class="form-group row">
					<div class="col-md-3">
						<label for="">Single Offer ID</label>
					</div>
					<div class="col-md-4" v-if="ebook && ebook.single">
						<strong>
							{{ ebook.single[0]?.id ?? 'Not Found' }}
						</strong>	
					</div>
				</div>
				<div class="form-group row">
					<div class="col-md-3">
						<label for="">Single Offer Status</label>
					</div>
					<div class="col-md-4" v-if="ebook && ebook.single">
						<strong>
							{{ statusOffer(ebook.single[0]?.offer_status ?? 0) }}
						</strong>	
					</div>
				</div>
				<div class="form-group row">
					<div class="col-md-3">
						<label for="">Priority</label>
					</div>
					<div class="col-md-4" v-if="ebook && ebook.single">
						<input type="number" v-model="priority" name="priority" class="form-control">
					</div>
				</div>
				<div class="form-group row">
					<div class="col-md-3">
						<p class="text-mute">
							<small>
								*required
								<ol>
									<li>
										Item Status must be Ready for Consume
									</li>
									<li>
										Item Must be is active
									</li>
									<li>
										Offer Status must be READY_FOR_SALE
									</li>
									<li>
										Internal Content must be False
									</li>
								</ol>
								</small>
						</p>
					</div>
				</div>
				<div class="form-group row justify-content-center">
					<div class="col-md-5">
						<button 
							:disabled="disable"
							class="btn btn-block btn-primary">
							<span v-if="disable">
								<i class="fa fa-ban"></i>
							</span>
							<span v-else>
								<i class="fa fa-save"></i>
							</span>
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>

import { mapState, mapActions,mapMutations} from "vuex";
import API from "../../plugins/http";
	export default {
		name: 'CreateLayouts',
		data() {
			return {
				currentPage: 1,
				perPage: 50,
				filter: {},
				isLoadingSearch: false,
				basePath:'https://ebooks.gramedia.com/ebook-covers/',
				selected: {},
				layoutID:0,
				disable:true,
				priority:10,
			}
		},
		computed: {
			// ...mapGetters("ebooks", ["ebooks"]),
			...mapState({
				isLoading: (state) => state.ebooks.isLoading,
				isError: (state) => state.ebooks.isError,
				totalRows: (state) => state.ebooks.totalRows,
				ebooks: (state) => state.ebooks.items,
				ebook : (state) => state.ebooks.item,
			}),
		},
		watch: {
			filter: function() {
				if(!this.filter) {
					this.$store.commit(`ebooks/setItem`,{})
					this.disable =true
				}
			},
			ebook: function() {
				if(this.ebook) {
					let offers = this.ebook?.single ?? [];
					let status = this.statusOffer(offers && offers.length > 0 ? offers[0].offer_status :0);
					if(status == 'READY_FOR_SALE'
						&& this.filter.item_status == 'ready for consume'
						&& this.filter.is_internal_content=== false
						&& this.filter.is_active===true){
						this.disable = false;
					}
				}
			}
		},
		mounted() {
			this.layoutID = this.$route.query.id;
			this.actionGetEbooks();
		},
		methods: {
			...mapActions("ebooks", ["fetchEbooks"]),
			...mapActions("ebooks", ["searchBooks", "fetchEbookById"]),
			...mapMutations(["setItems","setItem"]),
			actionGetEbooks() {
				let payload = {
					page: this.currentPage,
					limit: this.perPage,
				};
				this.fetchEbooks(payload);
			},
			formatPriceBasic(value) {
				let val = (value / 1).toFixed(0).replace(".", ",");
				return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
			},
			formatPrice(value) {
				if (value) {
					let formatter = null;
					formatter = new Intl.NumberFormat("en-ID", {
						style: "currency",
						currency: "IDR"
					});

					return formatter.format(value);
				}

				return "0";
			},
			asyncFind(query) {
				this.isLoadingSearch = true;
				clearTimeout(this.debounce);
				this.debounce = setTimeout(() => {
					if (!query) {
						this.isLoadingSearch = false;
						return;
					}
					let payload = {
						q: query,
						page: this.currentPage,
						limit: 10,
					};
					this.searchBooks(payload).then((response) => {
						this.ebooks = response.data.data.rows;
						this.isLoadingSearch = false;
					})
					.catch(() => {
						this.isLoadingSearch = false;
					});
				}, 2000);
			},
			customLabel({ name }) {
				if (name) {
					return name;
				} else {
					return "Type Here";
				}
			},
			removeBucket(str) {
        if (str && str.substring(0, 6) == "images") {
          return str.substring(9);
        } else {
          return str;
        }
      },
			actionFindOne() {
				setTimeout(() => {
					let payload = {
						id: this.filter.id,
						data: this.filter,
					};
					this.fetchEbookById(payload);
				}, 600);
			},
			createOffers() {
				let offers = this.ebook?.single ?? [];
				let payload = {
					layout_id: this.layoutID,
					offer_id: offers[0]?.id ?? 0,
					sort_priority:this.priority
				};
				API.post('/product-choices',payload)
				.then((response) => {
					let data = response.data;
					this.$swal({
						toast: "true",
						position: "top-end",
						icon: data.success ? "success" : "error",
						title: "success",
						showConfirmButton: false,
						timer: 3000,
						timerProgressBar: true,
					});
					this.$store.commit(`ebooks/setItem`,{})
					this.filter = {};
					this.disable =true
				})
				.catch((error) => {
					this.$swal({
						toast: "true",
						position: "top-end",
						icon: "error",
						title: error.message,
						showConfirmButton: false,
						timer: 3000,
						timerProgressBar: true,
					});
					this.disable =true
				});
			},
			statusOffer(offerStatus) {
				const OffersType = {
					1:'NEW',
					2:'WAITING_FOR_REVIEW',
					3:'IN_REVIEW',
					4:'REJECTED',
					5:'APPROVED',
					6:'PREPARE_FOR_SALE',
					7:'READY_FOR_SALE',
					8:'NOT_FOR_SALE',
				};
				return OffersType[offerStatus];
			}
		},
		
	}
</script>

<style lang="css" scoped>
.cover {
	width: 80%;
	height: 100%;
}
</style>